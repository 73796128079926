const firebaseConfig = {
    apiKey: "AIzaSyBkFRbH82Bjrf7gBQHEFsF5Lse3ekFmmBU",
    authDomain: "todos-2c7d4.firebaseapp.com",
    databaseURL: "https://todos-2c7d4.firebaseio.com",
    projectId: "todos-2c7d4",
    storageBucket: "todos-2c7d4.appspot.com",
    messagingSenderId: "764941521719",
    appId: "1:764941521719:web:14f7ebaba67a2b298400b2",
    measurementId: "G-LP9L9J3W5F"
};

export default firebaseConfig;